//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Statement",
  data () {
    return {
      types: { 1: "balance", 2: 'bet', 3: 'transfer' },
      result: [],
      loading: false,
      message: null
    };
  },
  mounted () {
    this.load()
  },
  computed: {
    isEmpty: function () {
      return this.result.length == 0;
    }
  },
  methods: {
    load: function () {
      this.loading = true;
      this.message = null;
      this.$store.dispatch("statement")
        .then(data => {
          this.result = data;
          this.loading = false;
        })
        .catch(err => {
          this.message = err.message;
          this.loading = false;
        })
    }
  }
};
