import { render, staticRenderFns } from "./Betlist.vue?vue&type=template&id=2f3f898d&scoped=true&"
import script from "./Betlist.vue?vue&type=script&lang=js&"
export * from "./Betlist.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f3f898d",
  null
  
)

export default component.exports
import {QPage,QPullToRefresh,QSpinnerDots,QCard,QCardSection} from 'quasar'
component.options.components = Object.assign({QPage,QPullToRefresh,QSpinnerDots,QCard,QCardSection}, component.options.components || {})
