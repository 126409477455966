//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Login',
  data () {
    return {
      tab: 'login',
      login: {
        loginId: null,
        password: null,
        rememberMe: false,
        accept: true
      },
      reset: {
        nickname: '',
        newPassword: '',
        confirmPassword: ''
      },
      isPwd: true,
      submiting: false
    }
  },
  mounted () {
    this.login = Object.assign(this.login, this.$store.state.rememberMe)
    console.log(this.$store.state.rememberMe)
    console.log(this.login)
  },
  computed: {
    passwordMatch: function () {
      return this.reset.newPassword !== this.reset.confirmPassword
    }
  },
  methods: {
    onLogin () {
      if (this.login.accept !== true) {
        this.$q.notify({
          color: 'red-5',
          textColor: 'white',
          icon: 'warning',
          message: this.$t('login.accept_error')
        })
      }
      else {
        this.submiting = true
        this.$store.dispatch('login', this.login)
          .then((data) => {
            this.submiting = false

            // Reset your password
            if (data.status === 1005) {
              this.tab = 'reset'
            }
            else {
              this.login = { loginId: null, password: null, rememberMe: false, accept: true }
              this.$emit('success')
            }
          })
          .catch(err => {
            this.submiting = false
            this.$q.notify({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: err.message
            })
          })
      }
    },
    onReset: function () {
      this.submiting = true
      this.$store.dispatch('reset', this.reset)
        .then(() => {
          // Feach user info
          this.reset = { nickname: null, newPassword: null, confirmPassword: null }
          this.submiting = false
          // this.$router.push("/m")
          this.$emit('success')
        })
        .catch(err => {
          this.submiting = false
          this.$q.notify({
            color: 'red-5',
            textColor: 'white',
            icon: 'warning',
            message: err.message
          })
        })
    }
  }
}
