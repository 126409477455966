//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Language',
  data () {
    return {
      languages: [
        { code: "en_US", name: "English" },
        { code: "zh_CHT", name: "繁體中文" },
        { code: "zh_CHS", name: "简体中文" },
        { code: "th", name: "ภาษาไทย" },
        { code: "vn", name: "Tiếng Việt" },
        { code: "my", name: "Burmese" },
        { code: "id", name: "Indonesian" }
      ]
    }
  },
  mounted () {
    this.$store.commit("title", this.$t('settings.language'))
  },
  computed: {
    selectedLangage: {
      get () {
        return this.$store.state.locale
      },
      set (value) {
        this.$i18n.locale = value
        this.$store.commit('locale', value)
      }
    }
  }
}
