//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'OddsType',
  data () {
    return {
      types: [
        { code: "DEC", name: "Decimal Odds" },
        { code: "MY", name: "Malay Odds" },
        { code: "HK", name: "Hong Kong Odds" },
        { code: "INDO", name: "Indonesia Odds" }
      ],
    }
  },
  mounted () {
    this.$store.commit("title", this.$t('settings.odds_type'))
  },
  computed: {
    selectedType: {
      get () {
        return this.$store.state.profiles.oddsType
      },
      set (value) {
        this.$store.commit('profiles', { oddsType: value })
      }
    }
  }
}
