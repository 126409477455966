//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Ticket from '../components/Ticket'
export default {
  name: "StatementDetails",
  components: {
    Ticket
  },
  data () {
    return {
      tab: 'bet-list',
      types: { 1: 'balance', 2: 'open', 3: 'settled' },
      result: [],
      loading: false,
      message: null,
    };
  },
  computed: {
    isEmpty: function () {
      return this.result.length === 0;
    },
    subtotal: function () {
      return this.result.filter(p => p.profit !== null)
        .map(m => m.profit)
        .reduce((a, b) => a + b, 0)
    },
    subtotalCommission: function () {
      return this.result.map(m => m.stakeBonus).reduce((a, b) => a + b, 0)
    },
    total: function () {
      return this.subtotal + this.subtotalCommission
    },
    betsummary: function () {
      const groupBy = (arr) => arr.map(val => val.selections[0].eventId)
        .reduce((acc, val, i) => {
          const selection = arr[i].selections[0]
          if (acc[val]) {
            acc[val].stake += arr[i].stake
            acc[val].credit += arr[i].profit || 0
          }
          else {
            acc[val] = {
              timestamp: selection.eventTime,
              homeName: selection.homeName,
              awayName: selection.awayName,
              leagueName: selection.leagueName,
              stake: arr[i].stake,
              credit: arr[i].profit || 0
            }
          }
          return acc
        }, {})

      var summary = groupBy(this.result)
      return Object.keys(summary).map(key => summary[key])
    }
  },
  mounted () {
    this.$store.commit("title", this.$route.params.type === "2" ? this.$t('statement.outstanding_bet') : this.$t('statement.betting_statement'))
    this.load()
  },
  methods: {
    load: function () {
      this.loading = true;
      this.message = null;

      let params = this.$route.params

      this.$store.dispatch("betlist", { timestamp: params.timestamp, status: this.types[params.type] })
        .then(data => {
          this.result = data;
          this.loading = false;
        })
        .catch(err => {
          this.message = err.message;
          this.loading = false;
        })
    }
  },
  filters: {
    winloss: function (val) {
      return val === 0 ? "draw" : (val > 0 ? "win" : "loss")
    }
  }
};
