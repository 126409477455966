//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Settings',
  data () {
    return {
      submiting: false,
      model: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      error: null,
    }
  },
  methods: {
    changeNickname: function () {

    },
    async changePassword () {
      if (await this.$refs.changePasswordObserver.validate()) {
        this.submiting = true
        this.$store.dispatch('changePassword', this.model).then(() => {
          this.model = { currentPassword: '', newPassword: '', confirmPassword: '' }
          this.submiting = false
        })
          .catch(err => {
            this.error = err
            this.submiting = false
          })
      }
    },
  }
}
