//
//
//
//
//
//
//
//
//
//
//
//

import OddsTable from '../components/OddsTable'

export default {
    name: 'HalfTimeFullTime',
    components: {
        OddsTable
    }
}
