//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Sports',
  data() {
    return {
      titles: [ 'name', 'sportsbook.live', 'sportsbook.today', 'sportsbook.early' ]
    }
  },
  computed: {
    title: function() {
      let sportsbook = this.$route.params.sportsbook
      return sportsbook == undefined ? this.titles[0] : this.titles[sportsbook]
    }
  },
}
