import { render, staticRenderFns } from "./Result.vue?vue&type=template&id=cd5963a6&scoped=true&"
import script from "./Result.vue?vue&type=script&lang=js&"
export * from "./Result.vue?vue&type=script&lang=js&"
import style0 from "./Result.vue?vue&type=style&index=0&id=cd5963a6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd5963a6",
  null
  
)

export default component.exports
import {QPage,QInput,QIcon,QPopupProxy,QDate,QList,QExpansionItem,QCard,QCardSection,QItemLabel} from 'quasar'
component.options.components = Object.assign({QPage,QInput,QIcon,QPopupProxy,QDate,QList,QExpansionItem,QCard,QCardSection,QItemLabel}, component.options.components || {})
