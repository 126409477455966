//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Statement',
  data () {
    return {
      types: { 1: "balance", 2: 'bet', 3: 'transfer' },
      result: [],
      loading: false
    };
  },
  mounted () {
    this.$store.commit("title", this.$t('navs.statement'))
    this.load()
  },
  computed: {
    isEmpty: function () {
      return this.result.length == 0;
    }
  },
  methods: {
    load: function () {
      this.loading = true;
      this.message = null;
      this.$store.dispatch("statement")
        .then(data => {
          this.result = data;
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          this.$q.notify({
            color: 'red-5',
            textColor: 'white',
            icon: 'info',
            position: 'top',
            message: err.message
          })
        })
    },
    details (statement) {
      console.log(statement.type)
      if (statement.type !== 1) {
        this.$router.push(`/m/statement/details/${statement.timestamp}/${statement.title === 'Betting Statement' ? 3 : statement.type}`)
      }
    }
  }
}
