var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"account settings"},[_c('div',{staticClass:"container mb-2 account-header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('i',{staticClass:"material-icons"},[_vm._v("settings")]),_vm._v(" "+_vm._s(_vm.$t('navs.settings'))+" ")])])]),_c('div',{staticClass:"container mb-2 account-grid"},[_c('ValidationObserver',{ref:"changePasswordObserver",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.changePassword()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"title"},[_c('h5',[_vm._v(_vm._s(_vm.$t('settings.change_account_password')))])]),_c('ValidationProvider',{attrs:{"rules":"required|min:6|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('div',{staticClass:"form-group",class:classes},[_c('label',{attrs:{"for":"currentPassword"}},[_vm._v(_vm._s(_vm.$t('settings.current_password')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.currentPassword),expression:"model.currentPassword"}],staticClass:"form-control",attrs:{"type":"password","aria-describedby":"passwordHelp","placeholder":"","id":"currentPassword"},domProps:{"value":(_vm.model.currentPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "currentPassword", $event.target.value)}}})])]}}],true)}),_c('ValidationProvider',{attrs:{"rules":"required|min:6|max:20","vid":"newPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('div',{staticClass:"form-group",class:classes},[_c('label',{attrs:{"for":"newPassword"}},[_vm._v(_vm._s(_vm.$t('settings.new_password')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.newPassword),expression:"model.newPassword"}],staticClass:"form-control",attrs:{"type":"password","aria-describedby":"passwordHelp","placeholder":"","id":"newPassword"},domProps:{"value":(_vm.model.newPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "newPassword", $event.target.value)}}})])]}}],true)}),_c('ValidationProvider',{attrs:{"rules":"required|min:6|max:20|confirmed:newPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('div',{staticClass:"form-group",class:classes},[_c('label',{attrs:{"for":"confirmPassword"}},[_vm._v(_vm._s(_vm.$t('settings.confirm_password')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.confirmPassword),expression:"model.confirmPassword"}],staticClass:"form-control",attrs:{"type":"password","aria-describedby":"passwordHelp","placeholder":"","id":"confirmPassword"},domProps:{"value":(_vm.model.confirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "confirmPassword", $event.target.value)}}}),_c('small',{staticClass:"form-text text-muted",attrs:{"id":"passwordHelp"}},[_vm._v(_vm._s(_vm.$t('settings.password_help')))])])]}}],true)}),_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-default mt-3",class:{ 'loading': _vm.submiting },attrs:{"type":"submit","disabled":invalid || _vm.submiting}},[_vm._v(" "+_vm._s(_vm.$t('settings.submit'))+" ")])]),(_vm.error)?_c('div',{staticClass:"alert alert-danger"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"alert-icon"},[_c('i',{staticClass:"material-icons"},[_vm._v("error_outline")])]),_vm._v(" "+_vm._s(_vm.error.message)+" ")])]):_vm._e()]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }