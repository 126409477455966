//
//
//
//
//
//
//
//

export default {
  name: 'Favorites',
  mounted () {
    this.$store.commit("title", this.$t('sb.my_favorites'))
  }
}
