import { render, staticRenderFns } from "./ChangePassword.vue?vue&type=template&id=8418cc3c&scoped=true&"
import script from "./ChangePassword.vue?vue&type=script&lang=js&"
export * from "./ChangePassword.vue?vue&type=script&lang=js&"
import style0 from "./ChangePassword.vue?vue&type=style&index=0&id=8418cc3c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8418cc3c",
  null
  
)

export default component.exports
import {QLayout,QForm,QHeader,QToolbar,QBtn,QToolbarTitle,QCard,QInput,QIcon,QItemLabel} from 'quasar'
component.options.components = Object.assign({QLayout,QForm,QHeader,QToolbar,QBtn,QToolbarTitle,QCard,QInput,QIcon,QItemLabel}, component.options.components || {})
