//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'LayoutSubpage',
  data () {
    return {
      model: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      isPwd: true,
      submiting: false
    }
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    onSubmit () {
      this.submiting = true
      this.$store.dispatch('changePassword', this.model)
        .then(() => {
          this.model = { currentPassword: '', newPassword: '', confirmPassword: '' }
          this.submiting = false
          this.back()
        })
        .catch(err => {
          this.submiting = false
          this.$q.notify({
            color: 'red-5',
            textColor: 'white',
            icon: 'warning',
            message: err.message
          })
        })
    }
  }
}
