//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "odds",
  props: {
    // [market_id, bet_type, handicap, stake_number, handicapDisplayName, odds, {"up" | "down"}],
    odds: Array,
    fixed: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      indicators: { }
    }
  },
  mounted() {
    
  },
  watch: {
    odds: function(newVal, oldVal) {
      if (oldVal) {
        this.indicators[`${ newVal[0] }-${ newVal[3] }`] = newVal[6] == 0 ? '' : newVal[6] > 0 ? 'up' : 'down'

        // var timer = 20
        let clock = window.setInterval(() => {
          window.clearInterval(clock)
          this.indicators = {}
        }, 5000);
      }
    }
  }
};
