//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Account',
  data () {
    return {
      user: this.$store.state.user
    }
  },
  mounted () {
    this.$store.commit("title", this.user.loginId)
    this.$store.dispatch('getUser')
      .then(user => {
        this.user = user
        this.$store.commit("title", this.user.loginId)
      })
  }
}
