import { render, staticRenderFns } from "./Account.vue?vue&type=template&id=74027c99&"
import script from "./Account.vue?vue&type=script&lang=js&"
export * from "./Account.vue?vue&type=script&lang=js&"
import style0 from "./Account.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QPage,QList,QItem,QItemSection,QSeparator,QLayout,QTabPanel} from 'quasar'
component.options.components = Object.assign({QPage,QList,QItem,QItemSection,QSeparator,QLayout,QTabPanel}, component.options.components || {})
