import { render, staticRenderFns } from "./OddsType.vue?vue&type=template&id=5a968481&scoped=true&"
import script from "./OddsType.vue?vue&type=script&lang=js&"
export * from "./OddsType.vue?vue&type=script&lang=js&"
import style0 from "./OddsType.vue?vue&type=style&index=0&id=5a968481&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a968481",
  null
  
)

export default component.exports
import {QPage,QList,QItem,QItemSection,QIcon} from 'quasar'
component.options.components = Object.assign({QPage,QList,QItem,QItemSection,QIcon}, component.options.components || {})
