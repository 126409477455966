//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Betlist",
  data() {
    return {
      // result: [
      //   {
      //     id: "107520620224",
      //     timestamp: 1569225097,
      //     stake: 25,
      //     status: "Running",
      //     from: "116.25.95.217",
      //     parlay: false,
      //     selections: [
      //       {
      //         awayId: 2,
      //         awayName: "New York Red Bulls",
      //         betTypeId: 1,
      //         betTypeName: "Over/Under",
      //         handicap: "-0.50",
      //         homeId: 1,
      //         homeName: "Protland Timbers",
      //         leagueId: 1,
      //         leaugeName: "USA Major League Soccer",
      //         marketId: 32260565,
      //         odds: 0.87,
      //         oddsType: "MY",
      //         soFar: "[0-1]",
      //         sportId: 1,
      //         sportName: "Soccer",
      //         stakeNumber: 0,
      //         stakeSelection: "Protland Timbers"
      //       }
      //     ]
      //   },
      //   {
      //     id: "107520620224",
      //     timestamp: 1569225097,
      //     stake: 25,
      //     status: "Running",
      //     from: "116.25.95.217",
      //     parlay: false,
      //     selections: [
      //       {
      //         awayId: 2,
      //         awayName: "New York Red Bulls",
      //         betTypeId: 1,
      //         betTypeName: "Over/Under",
      //         handicap: "-0.50",
      //         homeId: 1,
      //         homeName: "Protland Timbers",
      //         leagueId: 1,
      //         leaugeName: "USA Major League Soccer",
      //         marketId: 32260565,
      //         odds: -0.87,
      //         oddsType: "MY",
      //         soFar: "[0-1]",
      //         sportId: 1,
      //         sportName: "Soccer",
      //         stakeNumber: 0,
      //         stakeSelection: "Protland Timbers"
      //       }
      //     ]
      //   }
      // ]
      result: [],
      loading: false,
      message: null
    };
  },
  mounted() {
    this.load()
  },
  computed: {
    isEmpty: function() {
      return this.result.length == 0;
    }
  },
  methods: {
    load: function() {
      this.loading = true;
      this.message = null;
      this.$store.dispatch("betlist")
        .then(data => {
          this.result = data;
          this.loading = false;
        })
        .catch(err => {
          this.message = err.message;
          this.loading = false;
        })
    }
  }
};
