//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const languages = { 'en_US': "English", 'zh_CHT': "繁體中文", 'zh_CHS': "简体中文", 'th': "ภาษาไทย", 'vn': "Tiếng Việt", 'my': "Burmese", 'id': "Indonesian" }

export default {
  name: 'Settings',
  mounted () {
    this.$store.commit("title", this.$t('navs.settings'))
  },
  computed: {
    languageName () {
      return languages[this.$store.state.locale]
    }
  }
}
