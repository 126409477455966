import { render, staticRenderFns } from "./StatementDetails.vue?vue&type=template&id=eb260da2&scoped=true&"
import script from "./StatementDetails.vue?vue&type=script&lang=js&"
export * from "./StatementDetails.vue?vue&type=script&lang=js&"
import style0 from "./StatementDetails.vue?vue&type=style&index=0&id=eb260da2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb260da2",
  null
  
)

export default component.exports
import {QPage,QTabs,QTab,QTabPanels,QTabPanel,QSpinnerDots,QCard,QCardSection,QItemLabel} from 'quasar'
component.options.components = Object.assign({QPage,QTabs,QTab,QTabPanels,QTabPanel,QSpinnerDots,QCard,QCardSection,QItemLabel}, component.options.components || {})
