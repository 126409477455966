//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Ticket from '../components/Ticket'

export default {
  name: 'Betlist',
  components: {
    Ticket
  },
  data () {
    return {
      loading: false,
      tickets: []
    }
  },
  mounted () {
    this.load()

    this.$store.commit("title", this.$t('navs.betlist'))
  },
  methods: {
    load: function () {
      // console.log(`Fetching ${tab.name} betlist.`)
      this.tickets = []
      this.loading = true
      // var hasWaiting = tab.name === 'waiting' && tab.tickets.length > 0
      this.$store.dispatch("betlist")
        .then(data => {
          this.tickets = data;
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          this.$q.notify({
            color: 'red-5',
            textColor: 'white',
            icon: 'info',
            position: 'top',
            message: err.message
          })
        })
    },
    refresh (done) {
      this.$store.dispatch("betlist")
        .then(data => {
          this.tickets = data;
          done()
        })
        .catch(err => {
          done()
          this.$q.notify({
            color: 'red-5',
            textColor: 'white',
            icon: 'info',
            position: 'top',
            message: err.message
          })
        })
    }
  }
}
