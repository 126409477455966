//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Result',
  data () {
    return {
      loading: false,
      allLeague: { id: 0, name: "All Leagues" },
      filter: {
        date: '2020-04-03', //new Date().add(-1).format('yyyy-MM-dd'),
        dataRange: `${new Date().add(-3).format('yyyy-MM-dd')} to ${new Date().format('yyyy-MM-dd')}`,
        sport: { id: 1, name: "Soccer " },
        league: { id: 0, name: "All Leagues" }
      },
      result: {
        sports: [{ id: 1, name: "Soccer" }],
        leagues: [],
        result: []
      },
      outright: {
        sports: [],
        leagues: [],
        result: []
      }
    };
  },
  mounted () {
    this.$store.commit("title", this.$t('navs.result'))
    this.filter.sport = this.result.sports[0];

    this.$watch("filter.date", (newVal) => {
      if (newVal) {
        this.load(this.filter.sport.id, newVal)
      }
    }, { immediate: true })

    this.$watch('$i18n.locale', (newVal, oldVal) => {
      if (oldVal) {
        this.load(this.filter.sport.id, this.filter.date)
      }
    },
      { immediate: true })
  },
  computed: {
    isEmpty: function () {
      return this.result.result.length === 0;
    },
    isEmptyOfOutright: function () {
      return this.outright.result.length === 0;
    }
  },
  methods: {
    load: function (sportId, date) {
      if (!this.loading) {
        this.result.result = []
        this.filter.league = this.allLeague
        this.loading = true
        this.$store.dispatch('getResults', { sportId, date })
          .then(data => {
            this.result.leagues = [this.allLeague, ...data.map(l => { return { id: l.leagueId, name: l.leagueName }; })]
            this.result.result = data
            this.loading = false
          })
      }
    },
    isWin: function (match, func) {
      let scores = match.ftScore.split("-");
      return func(scores[0], scores[1]);
    }
  }
}
